const local = JSON.parse(localStorage.getItem('userData'))
let PdfGuides = [{
  title: 'PDF Documents (english)',
  route: 'guides_pdf/english',
  except: 'casino',
  action: 'read',
  resource: 'General',
},
{
  title: 'PDF Documents (italian)',
  route: 'guides_pdf/italian',
  except: 'casino',
  action: 'read',
  resource: 'General',
}]
let videoGuidesURL = 'guides_videos'
if (local.account_type === 2 && !local.isAdmin && !local.isUserAffiliate) {
  PdfGuides = []
  videoGuidesURL = 'guides_videos_casino'
}

if (local.isAdmin) {
  PdfGuides = [...PdfGuides,
    {
      title: 'Videos (for Casino Users)',
      route: 'guides_videos_casino',
      except: 'casino',
      action: 'read',
      resource: 'General',
    }]
}

export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'General',
  },
  {
    title: 'Request Payment',
    route: 'request-payment',
    icon: 'EditIcon',
    action: 'read',
    resource: 'Affiliate',
  },
  {
    title: 'Newsletter',
    route: 'news',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'Af-User',
  },
  {
    title: 'Configuration',
    route: 'user-configuration',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'User',
  },
  // {
  //   title: 'Affiliate Conference',
  //   route: 'affiliate-conference',
  //   icon: 'CalendarIcon',
  //   tagVariant: 'light-danger',
  //   action: 'read',
  //   resource: 'Admin',
  //   children: [
  //     {
  //       title: 'Affiliates Tickets (FREE and Paid)',
  //       route: 'affiliate-conference-tickets',
  //       action: 'read',
  //       resource: 'Admin',
  //     },
  //     {
  //       title: 'VIP/Sponsor/Speaker Tickets',
  //       route: 'affiliate-conference-tickets-vip',
  //       action: 'read',
  //       resource: 'Admin',
  //     },
  //     {
  //       title: 'Event Sponsors Requests',
  //       route: 'affiliate-conference-event',
  //       action: 'read',
  //       resource: 'Admin',
  //     },
  //     {
  //       title: 'Event Speakers Requests',
  //       route: 'affiliate-conference-speakers',
  //       action: 'read',
  //       resource: 'Admin',
  //     },
  //     {
  //       title: 'Newsletter Subscribers',
  //       route: 'affiliate-conference-newsletter',
  //       action: 'read',
  //       resource: 'Admin',
  //     },
  // {
  //   title: 'Tickets Invoices',
  //   route: 'affiliate-conference-invoices',
  //   action: 'read',
  //   resource: 'Admin',
  // },
  //   ],
  // },
  {
    title: 'Leads',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Admin',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'Pending',
        route: 'form-leads-pending',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'To Call Back',
        route: 'form-leads-callback',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Called',
        route: 'form-leads-called',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Rejected',
        route: 'form-leads-rejected',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'External',
        route: 'form-leads-external',
        action: 'read',
        resource: 'Admin',
      },
    ],
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    tagVariant: 'light-danger',
    resource: 'Admin',
    children: [
      {
        title: 'All',
        route: 'users-all',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Subscribed',
        route: 'users-subscribed',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Trial Users',
        route: 'users-trial-users',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Pending Users',
        route: 'users-pending-users',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Registered',
        route: 'users-registered',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Leads (Not-Reg)',
        route: 'users-leads',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Canceled',
        route: 'users-canceled',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Expired/Suspended',
        route: 'users-expired',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Affiliates',
        route: 'users-affiliates',
        action: 'read',
        resource: 'Admin',
      },
    ],
  },
  {
    title: 'Support Tickets',
    icon: 'CheckSquareIcon',
    action: 'read',
    resource: 'Ticket',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'New Ticket',
        route: 'tickets-new-ticket',
        action: 'read',
        resource: 'Ticket',
      },
      {
        title: 'Open Tickets',
        route: 'tickets-open-tickets',
        action: 'read',
        resource: 'Ticket',
      },
      {
        title: 'Closed Tickets',
        route: 'tickets-closed-tickets',
        action: 'read',
        resource: 'Ticket',
      },
      {
        title: 'Response Templates',
        route: 'response-templates',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Statistics',
        route: 'tickets-statistics',
        action: 'read',
        resource: 'Admin',
      },
    ],
  },
  {
    title: 'Anti-fraud',
    icon: 'EyeIcon',
    action: 'read',
    resource: 'Admin',
    children: [
      {
        title: 'Admin',
        route: 'anti-fraud-admin',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Clients',
        route: 'anti-fraud-clients',
        action: 'read',
        resource: 'Admin',
      },
    ],
  },
  {
    title: 'Anti-fraud Credits',
    route: 'anti-fraud-credits',
    icon: 'EyeIcon',
    action: 'read',
    resource: 'User',
  },
  // {
  //   title: 'Packages',
  //   icon: 'PackageIcon',
  //   action: 'read',
  //   resource: 'Admin',
  //   route: 'packages',
  // },
  {
    title: 'Billing',
    icon: 'CreditCardIcon',
    action: 'read',
    resource: 'Billing',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'Invoices Register',
        route: 'billing-invoices-register',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Pending Pro Forma Invoices',
        route: 'billing-pending-forma-invoices',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Overdue Pro Forma Invoices',
        route: 'billing-overdue-forma-invoices',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Payments Register',
        route: 'billing-payments-register',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Banking Profiles',
        route: 'billing-banking-profiles',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Ext. Users Profiles',
        route: 'billing-ext-users-profiles',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Affiliate Payments',
        route: 'billing-affiliate-payments',
        action: 'read',
        resource: 'Admin',
        tagVariant: 'light-danger',
      },
      {
        title: 'Current Invoice',
        route: 'invoice-current',
        action: 'read',
        resource: 'CurrentInvoice',
      },
      {
        title: 'Pro Forma History',
        route: 'billing-pro-forma-history',
        action: 'read',
        resource: 'User',
      },
      {
        title: 'Invoice History',
        route: 'billing-invoice-history',
        action: 'read',
        resource: 'User',
      },
      {
        title: 'Payment History',
        route: 'billing-payment-history',
        action: 'read',
        resource: 'User',
      },
      {
        title: 'Wire Transfers',
        route: 'billing-wire-transfers',
        action: 'read',
        tagVariant: 'light-danger',
        resource: 'Admin',
      },
      // {
      //   title: 'BTC Transfer',
      //   route: 'billing-btc-transfer',
      //   action: 'read',
      //   resource: 'Admin',
      //   tagVariant: 'light-danger',
      // },
      // {
      //   title: 'Balance Payments',
      //   route: 'billing-balance',
      //   action: 'read',
      //   resource: 'Admin',
      //   tagVariant: 'light-danger',
      // },
    ],
  },
  {
    title: 'Advanced Settings',
    icon: 'ToolIcon',
    action: 'read',
    resource: 'User',
    children: [
      {
        title: 'Custom DNS',
        route: 'custom-dns',
        action: 'read',
        resource: 'User',
      },
    ],
  },
  {
    title: 'Affiliate Program',
    icon: 'Edit3Icon',
    action: 'read',
    resource: 'AffiliateUser',
    children: [
      {
        title: 'Stats',
        route: 'affiliat-user-statistics',
        action: 'read',
        resource: 'AffiliateUser',
      },
      {
        title: 'Request Payment',
        route: 'affiliat-user-payments',
        action: 'read',
        resource: 'AffiliateUser',
      },
    ],
  },
  {
    title: 'Optional Features',
    route: 'optional-features',
    icon: 'PlusSquareIcon',
    action: 'read',
    resource: 'General',
  },
  {
    title: 'Access Your Platform',
    route: 'user-access-platform',
    icon: 'CloudIcon',
    action: 'read',
    resource: 'Platform',
  },
  {
    title: 'News',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'Admin',
    children: [
      {
        title: 'List',
        route: 'news-list',
        action: 'read',
        resource: 'Admin',
      },
      {
        title: 'Add',
        route: 'news-add',
        action: 'read',
        resource: 'Admin',
      },
    ],
  },
  {
    title: 'Create Account',
    icon: 'UserPlusIcon',
    action: 'read',
    resource: 'Admin',
    route: 'create-account',
  },
  {
    title: 'Guides',
    route: 'guides',
    icon: 'InfoIcon',
    action: 'read',
    resource: 'General',
    children: [
      ...PdfGuides,
      {
        title: 'Videos',
        route: videoGuidesURL,
        action: 'read',
        resource: 'General',
      },
    ],
  },
  {
    title: 'Product Updates',
    route: 'product_updates',
    icon: 'PrinterIcon',
    action: 'read',
    resource: 'Af-User',
  },
  {
    title: 'Limited Accounts',
    route: 'create_limited_account',
    icon: 'UserPlusIcon',
    action: 'read',
    resource: 'User',
  },
  // FROM HERE DOWN, IS THE LIMITED_ACC_PERMISIONS
  {
    title: 'Account Billing',
    icon: 'CreditCardIcon',
    action: 'read',
    resource: 'BillingLimited',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'Current Invoice',
        route: 'invoice-current-limited',
        action: 'read',
        resource: 'CurrentInvoice',
      },
      {
        title: 'Invoice History',
        route: 'billing-invoice-history-limited',
        action: 'read',
        resource: 'InvoicesHistoryLimited',
      },
      {
        title: 'Payment History',
        route: 'billing-payment-history-limited',
        action: 'read',
        resource: 'PaymentsHistoryLimited',
      },
      {
        title: 'Pro Forma History',
        route: 'billing-pro-forma-history-limited',
        action: 'read',
        resource: 'ProFormaHistoryLimited',
      },
    ],
  },
  {
    title: 'Account Optional Features',
    route: 'optional-features-limited',
    icon: 'PlusSquareIcon',
    action: 'read',
    resource: 'OptionalFeaturesLimited',
  },
]
